<template>
    <div class="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-bg-alabaster">
        <div class="tw-z-10 tw-h-full tw-w-full tw-bg-neutral-900">
            <video-view @end="onEnd" :interaction="session as Session" />
        </div>
    </div>
</template>
<script setup lang="ts">
    import ApendayVideo from '@/classes/apenday-video';
    import VideoView from '@/components/video/VideoView.vue';
    import { useInteraction } from '@/composables/interaction';
    import { useSessionStore } from '@/store/session';
    import { storeToRefs } from 'pinia';
    import { onMounted, onUnmounted, Ref } from 'vue';
    import { useRouter } from 'vue-router';

    const sessionStore = useSessionStore();
    const { session } = storeToRefs(sessionStore);
    const router = useRouter();
    console.log(session.value.area.assist_config.default_camera);
    let client: ApendayVideo;
    const { getInstance } = useInteraction(session as Ref<Session>);
    client = getInstance() as ApendayVideo;

    const errorHandler = (error) => {
        if (error === 'Room not found') {
            router.replace({ name: 'closed' });
        }
    };

    onMounted(async () => {
        client.onWithReplay('error', errorHandler);
    });
    onUnmounted(async () => {
        client.off('error', errorHandler);
    });

    function onEnd() {
        // check if satisfaction survey is enabled
        if (session.value.area.assist_config.enabled_satisfaction_survey) {
            router.replace({ name: 'satisfaction' });
        } else {
            router.replace({ name: 'closed' });
        }
    }
</script>
